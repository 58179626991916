import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import config, { SystemStatusData, WheelLiveCountsData } from "../../config";
import VerticalSlider from "../../components/VerticalSlider";
import { ScaleComponent } from "../../components/ScaleComponent";
import CardComponent from "../../components/CardComponent";
import { ChartColumnDataComponent, ChartColumnDataTitlesComponent } from "../../components/ChartColumnDataComponent";
import { ChartColumnTopComponent } from "../../components/ChartColumnTopComponent";
import { ChartColumnBarComponent } from "../../components/ChartColumnBarComponent";

const { getUrl, STATUS, LIVECOUNTS } = config?.api;

const HEADER_NAME = "";

const TOP_ROW_HEIGHT_SCREEN_PERCENTAGE = 10;
const DATA_ROW_HEIGHT_SCREEN_PERCENTAGE = 5;

const BAR_CHART_PERCENTAGE_WARNING_RANGE = { above: 5, below: 5 };
const BAR_CHART_MIN_BAR_HEIGHT_PERCENTAGE = 8;
const MIN_DISPLAYED_BAR_PERCENTAGE = 70;
const NUM_DISPLAYED_BAR_TICKS_INCLUSIVE = 7;

interface DataRowInfo {
  prop: string;
  checked: boolean;
  primary: string;
}

const DEFAULT_FIRST_DATA_ROWS_INFO: DataRowInfo[] = [
  { prop: "speed", checked: true, primary: "Speed" },
  { prop: "misses", checked: true, primary: "Misses" },
  { prop: "doubles", checked: true, primary: "Doubles" },
  { prop: "notIdeal", checked: true, primary: "Not Ideal" },
  { prop: "ideal", checked: true, primary: "Ideal" },
  { prop: "idealPercent", checked: true, primary: "% Ideal" },
];

const gpsSpeedHeader = "GPS Speed";
const averageSpacingHeader = "Avg. Spacing";
const targetSpacingHeader = "Target Spacing";
const cwtPerAcreHeader = "Cwt/ac";
const totalCwtHeader = "Total Cwt";
const acresHeader = "Acres";

const sliderValue = 75;

const visibleDataRowsConfig: DataRowInfo[] = DEFAULT_FIRST_DATA_ROWS_INFO.filter((info) => info.checked);
const topRowHeightStyles = {
  height: `calc(var(--child-container-height) * ${TOP_ROW_HEIGHT_SCREEN_PERCENTAGE} / 100.0`,
};
const dataRowsHeight = visibleDataRowsConfig.length * DATA_ROW_HEIGHT_SCREEN_PERCENTAGE;
const dataRowHeightStyles = { height: `calc(var(--child-container-height) * ${dataRowsHeight} / 100.0` };
const middleRowHeight = 100 - TOP_ROW_HEIGHT_SCREEN_PERCENTAGE - dataRowsHeight;
const middleRowHeightStyles = { height: `calc(var(--child-container-height) * ${middleRowHeight} / 100.0` };

interface HomePageServerData {
  systemStatus: SystemStatusData | undefined;
  wheelsLiveCounts: WheelLiveCountsData[] | undefined;
}

const Live = () => {
  const { systemId } = useParams();
  const { headersWithAuth, isAuthenticating, isAuthenticated } = React.useContext(FrontEndContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  const { data: homePageServerData } = useQuery<HomePageServerData>(
    "Retrieve Live Counts",
    async () => {
      // keep the live data loop going even if the server is not connected but return
      // empty data without making the requests to the server.
      if (!systemId) {
        return {
          systemStatus: undefined,
          wheelsLiveCounts: undefined,
        };
      }

      const { data: systemStatus } = await axios.get<SystemStatusData>(getUrl(systemId, STATUS), headersWithAuth);
      const { data: wheelsLiveCounts } = await axios.get<WheelLiveCountsData[]>(
        getUrl(systemId, LIVECOUNTS),
        headersWithAuth
      );

      return {
        systemStatus,
        wheelsLiveCounts,
      };
    },
    { refetchInterval: 1000 }
  );

  if (!systemId) {
    return <h1>Connecting to Server...</h1>;
  }

  if (homePageServerData == null) {
    return <h1>Loading...</h1>;
  }

  const activePlanting = true;
  const { systemStatus, wheelsLiveCounts } = homePageServerData as HomePageServerData;
  const gpsSpeed = systemStatus?.currentSpeedMph ? systemStatus.currentSpeedMph.toFixed(2) : "-";
  const averageSpacing = systemStatus?.averageSpacingInches ? systemStatus.averageSpacingInches.toFixed(2) : "-";
  const targetSpacing = systemStatus?.targetSpacing ? systemStatus.targetSpacing.toFixed(2) : "-";
  const acres = systemStatus?.acreage ? systemStatus.acreage.toFixed(2) : "-";
  const cwtPerAcre = systemStatus?.cWeightPerAcre ? systemStatus.cWeightPerAcre.toFixed(2) : "-";
  const totalCwt = systemStatus?.totalCWeight ? systemStatus.totalCWeight.toFixed(2) : "-";

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
      <div className="live-container">
        <div className="my-container">
          <div className="row p-0">
            {/* left hand column */}
            <div className="col h-100 width-lh-col p-0 m-0">
              {/* logo in the top-left corner */}
              <div
                className="row p-0 w-100 m-0 justify-content-center align-items-center"
                style={topRowHeightStyles}
              ></div>

              {/* scale lines */}
              <div className="row p-0 m-0 d-flex" style={middleRowHeightStyles}>
                <ScaleComponent
                  numDisplayedTicksInclusive={NUM_DISPLAYED_BAR_TICKS_INCLUSIVE}
                  minDisplayedPercentage={MIN_DISPLAYED_BAR_PERCENTAGE}
                />
              </div>

              {/* labels for the data items */}
              <div className="row p-0" style={dataRowHeightStyles}>
                <ChartColumnDataTitlesComponent visibleDataRowsConfig={visibleDataRowsConfig} />
              </div>
            </div>

            {/* middle column with bar graphs */}
            <div className="width-center-col h-100">
              {/* individual wheel column of information including bar graph */}
              <div className="row">
                {wheelsLiveCounts &&
                  wheelsLiveCounts
                    .sort((a, b) => a?.wheelNumber - b?.wheelNumber)
                    .map((wheelLiveCounts: WheelLiveCountsData) => {
                      const {
                        status,
                        wheelNumber,
                        wheelSpeed,
                        wheelPressure,
                        batteryStateOfCharge,
                        misses,
                        doubles,
                        ideal,
                        notIdeal,
                        successPercent,
                        successPercentRow,
                      } = wheelLiveCounts || {};

                      return (
                        <div className="col-2" key={wheelNumber}>
                          {/* top row of information (in a bordered box) for each wheel */}
                          <div className="row pt-2" style={topRowHeightStyles}>
                            <ChartColumnTopComponent
                              enabled={status !== 0}
                              PSI={`${wheelPressure}`}
                              batteryLevel={`${batteryStateOfCharge}`}
                            />
                          </div>

                          {/* bar chart for each wheel */}
                          <div className="row p-2 border" style={middleRowHeightStyles}>
                            <ChartColumnBarComponent
                              active={!!activePlanting}
                              percentage={Math.round(successPercent)}
                              scalePercentage={sliderValue}
                              warningRange={BAR_CHART_PERCENTAGE_WARNING_RANGE}
                              minBarHeightPercentage={BAR_CHART_MIN_BAR_HEIGHT_PERCENTAGE}
                              minDisplayedPercentage={MIN_DISPLAYED_BAR_PERCENTAGE}
                            />
                          </div>

                          {/* data items for each wheel */}
                          <div className="row p-0 m-0" style={dataRowHeightStyles}>
                            <ChartColumnDataComponent
                              visibleDataRowsConfig={visibleDataRowsConfig}
                              speed={`${wheelSpeed}`}
                              misses={`${misses}`}
                              doubles={`${doubles}`}
                              notIdeal={`${notIdeal}`}
                              ideal={`${ideal}`}
                              idealPercent={`${Math.round(successPercentRow || successPercent)}%`}
                            />
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>

            {/* right hand column with cards */}
            <div className="p-0 m-0 width-rh-col">
              <div
                className="row w-100 p-0 m-0 justify-content-center align-items-center"
                style={topRowHeightStyles}
              ></div>
              <div className="row w-100 p-0 m-0">
                <CardComponent header={gpsSpeedHeader} text={gpsSpeed} />
                <CardComponent header={averageSpacingHeader} text={averageSpacing} />
                <CardComponent header={targetSpacingHeader} text={targetSpacing} />
                <CardComponent header={cwtPerAcreHeader} text={cwtPerAcre} />
                <CardComponent header={totalCwtHeader} text={totalCwt} />
                <CardComponent header={acresHeader} text={acres} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Live;
