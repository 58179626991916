const DATE_LOCALE = "en-US";
const DATE_FORMAT_OPTIONS: any = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "numeric",
  hour12: true,
};

export const formatTime = (time: string) => new Date(time).toLocaleString(DATE_LOCALE, DATE_FORMAT_OPTIONS);
