import { useNavigate } from "react-router-dom";
import MUIDataTable, { FilterType, SelectableRows } from "mui-datatables";
import { Button } from "@mui/material";
import { formatTime } from "../../utils";
import { DisplayablePlantingData } from "../../config";
import "./index.css";

interface LiveViewListProps {
  dataLoaded: boolean;
  activePlantings?: DisplayablePlantingData[];
}

export default function LiveViewList(props: LiveViewListProps) {
  const { dataLoaded, activePlantings } = props;
  const navigate = useNavigate();
  const data = (dataLoaded && activePlantings) || [];

  const options = {
    setCellProps: () => ({
      align: "center",
    }),
  };

  const viewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any) => (
      <Button variant="contained" onClick={() => navigate(`/live/${value}`)}>
        Live
      </Button>
    ),
  };

  const dateOptions = {
    customBodyRender: (value: any) => formatTime(value),
    setCellProps: () => ({
      align: "center",
    }),
  };

  const COLUMNS = [
    { label: " ", name: "id", options: viewOptions },
    { label: "System", name: "systemName", options },
    { label: "Farm", name: "farm", options },
    { label: "Field", name: "field", options },
    { label: "Variety", name: "variety", options },
    { label: "Seedlot", name: "seedlot", options },
    { label: "Range", name: "range", options },
    { label: "Acreage", name: "acreage", options },
    { label: "Seed Spacing", name: "seedSpacing", options },
    { label: "Seed Weight", name: "seedWeight", options },
    { label: "Last Updated", name: "updateTime", options: dateOptions },
  ];

  const OPTIONS = {
    download: false,
    filter: true,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    search: true,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "updateTime",
      direction: "desc" as any,
    },
    storageKey: "active-planting",
    textLabels: {
      body: {
        noMatch: dataLoaded ? "No active plantings available." : "Loading ...",
      },
    },
  };

  return (
    <div className="field-log-table">
      <MUIDataTable title={"Active Plantings"} data={data} columns={COLUMNS} options={OPTIONS} />
    </div>
  );
}
