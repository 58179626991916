import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth } from "aws-amplify";
import axios, { AxiosError, AxiosResponse } from "axios";
import FrontEndContext, { FrontEndContextInterface, SystemInfo } from "./context/FrontEndContext";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Login from "./pages/Login";
import Planting from "./pages/Planting";
import Live from "./pages/Live";
import config, { ApiPlantingData, DisplayablePlantingData, PlantingType, PLANTING_TYPE_NAMES } from "./config";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

const {
  SYSTEMS,
  getUrl,
  FARMS,
  FIELDS,
  SEEDLOTS,
  VARIETIES,
  API_FAIL_ERROR,
  ACTIVEPLANTING,
  OPENPLANTINGS,
  CLOSEDPLANTINGS,
} = config?.api;

const EMPTY_SYSTEM_INFO: SystemInfo = {
  loaded: false,
  systemId: "",
  systemName: "",
  farms: [],
  fields: [],
  seedlots: [],
  varieties: [],
};

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bearerToken, setBearerToken] = useState("");
  const [systems, setSystems] = useState<SystemInfo[]>([]);
  const [systemsDataRequested, setSystemsDataRequested] = useState<boolean>(false);
  const [activePlantings, setActivePlantings] = useState<DisplayablePlantingData[]>([]);
  const [openPlantings, setOpenPlantings] = useState<DisplayablePlantingData[]>([]);
  const [closedPlantings, setClosedPlantings] = useState<DisplayablePlantingData[]>([]);
  const [plantingsRequested, setPlantingsRequested] = useState<boolean>(false);

  const navigate = useNavigate();

  const systemInfoFromId = useCallback(
    (systemId: string): SystemInfo | null => {
      const index = systems.findIndex((system) => system.systemId === systemId);
      if (index === -1) return null;
      return systems[index];
    },
    [systems]
  );

  const getFarmName = useCallback(
    (systemId: string, id: number) => {
      return systemInfoFromId(systemId)?.farms.find((farm) => farm.id === id)?.name || "unknown";
    },
    [systemInfoFromId]
  );

  const getFieldName = useCallback(
    (systemId: string, id: number) => {
      return systemInfoFromId(systemId)?.fields.find((field) => field.id === id)?.name || "unknown";
    },
    [systemInfoFromId]
  );

  const getSeedlotName = useCallback(
    (systemId: string, id: number) => {
      return systemInfoFromId(systemId)?.seedlots.find((seedlot) => seedlot.id === id)?.name || "unknown";
    },
    [systemInfoFromId]
  );

  const getVarietyName = useCallback(
    (systemId: string, id: number) => {
      return systemInfoFromId(systemId)?.varieties.find((variety) => variety.id === id)?.name || "unknown";
    },
    [systemInfoFromId]
  );

  const headersWithAuth: any = useMemo(() => {
    if (!bearerToken) {
      return undefined;
    }
    return { headers: { Authorization: `Bearer ${bearerToken}` } };
  }, [bearerToken]);

  const resetApp = () => {
    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setBearerToken("");
    setSystems([]);
    setSystemsDataRequested(false);
    setActivePlantings([]);
    setOpenPlantings([]);
    setClosedPlantings([]);
    setPlantingsRequested(false);
  };

  const handleAuthentication = useCallback((userSession: any, error?: any) => {
    if (userSession) {
      setIsAuthenticated(true);
      setBearerToken(userSession.getIdToken().getJwtToken());
    } else {
      // When not logged in, reset the app
      resetApp();

      // No user is signed in. This is not an error but is returned as an exception.
      if (error !== "No current user") {
        setBearerToken("");
        const errorMsg = `Authentication error: ${(error as any)?.message || "unknown error"}`;
        console.log(errorMsg);
        alert(errorMsg);
      }
    }

    setIsAuthenticating(false);
  }, []);

  useEffect(() => {
    async function onLoad() {
      try {
        const currentSession = await Auth.currentSession();
        handleAuthentication(currentSession);
      } catch (e) {
        handleAuthentication(null, e);
      }
    }

    onLoad();
  }, [handleAuthentication]);

  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    async function getSystems() {
      interface ApiSystemsData {
        systemId: string;
        systemName: string;
      }

      try {
        const systemsResponse = await axios.get<ApiSystemsData[]>(SYSTEMS, headersWithAuth);
        if (systemsResponse?.status >= 400) {
          console.log(`[Systems] ${API_FAIL_ERROR}${systemsResponse?.status}`);
        }

        const systemInfos: ApiSystemsData[] = systemsResponse?.data;
        if (systemInfos && systemInfos.length > 0) {
          setSystems(
            systemInfos.map((systemInfo) => ({
              ...EMPTY_SYSTEM_INFO,
              systemId: systemInfo.systemId,
              systemName: systemInfo.systemName,
            }))
          );
        } else {
          const errorMsg = "[Systems] No systems associated with this user.";
          console.log(errorMsg);
          alert(errorMsg);
          doLogout();
        }

        // no server access, so mark the server as being offline
      } catch (error) {
        console.log(`[Systems] ${API_FAIL_ERROR}${error}`);
      }
    }

    getSystems();
  }, [headersWithAuth, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (systems.length === 0 || systemsDataRequested) {
      return;
    }

    async function getServerInfo<T>(
      systemId: string,
      route: string,
      name: string,
      updateFn: React.Dispatch<React.SetStateAction<any>>
    ) {
      try {
        const response = await axios.get<T>(getUrl(systemId, route), headersWithAuth);
        if (response?.status >= 400) {
          console.log(`[${name}] ${API_FAIL_ERROR}${response?.status}`);
        }
        updateFn(response.data);

        // no server access, so mark the server as being offline
      } catch (error) {
        console.log(`[${name}] ${API_FAIL_ERROR}${error}`);
      }
    }

    async function getSystemData(systemId: string, index: number) {
      const systemData = systems[index];

      await getServerInfo(systemId, FARMS, "Farms", (farms) => {
        systemData.farms = farms;
      });
      await getServerInfo(systemId, FIELDS, "Fields", (fields) => {
        systemData.fields = fields;
      });
      await getServerInfo(systemId, SEEDLOTS, "Seedlots", (seedlots) => {
        systemData.seedlots = seedlots;
      });
      await getServerInfo(systemId, VARIETIES, "Varieties", (varieties) => {
        systemData.varieties = varieties;
      });

      systemData.loaded = true;

      const updatedSystems = [...systems];
      updatedSystems[index] = systemData;
      setSystems(updatedSystems);
    }

    systems.forEach((system, index) => {
      getSystemData(system.systemId, index);
    });
    setSystemsDataRequested(true);
  }, [headersWithAuth, systemsDataRequested, systems]);

  useEffect(() => {
    if (systems.length === 0 || systems.findIndex((system) => !system.loaded) >= 0 || plantingsRequested) {
      return;
    }

    const convertToDisplayable = (
      systemId: string,
      systemName: string,
      planting: ApiPlantingData,
      type: PlantingType
    ): DisplayablePlantingData => {
      if (!planting) {
        return undefined as unknown as DisplayablePlantingData;
      }

      const seedSpacing = 0;
      const { id, range, acreage, seedWeight, updateTime } = planting;
      // calculating the `id` field as a combination of the systemId and the id of the planting
      // separated by a slash is important as this value is used as the last part of the URL that
      // is used to access the planting or the live data.
      const idEncodedPath = `${systemId}/${id}`;
      return {
        systemName,
        id: idEncodedPath,
        type: PLANTING_TYPE_NAMES[type],
        farm: getFarmName(systemId, planting.farmId),
        field: getFieldName(systemId, planting.fieldId),
        variety: getVarietyName(systemId, planting.varietyId),
        seedlot: getSeedlotName(systemId, planting.seedlotId),
        range,
        acreage,
        seedSpacing,
        seedWeight,
        updateTime,
      };
    };

    const getAllPlantings = async (systemId: string, systemName: string) => {
      try {
        const active = await axios.get<ApiPlantingData>(getUrl(systemId, ACTIVEPLANTING), headersWithAuth);
        const open = await axios.get<ApiPlantingData[]>(getUrl(systemId, OPENPLANTINGS), headersWithAuth);
        const closed = await axios.get<ApiPlantingData[]>(getUrl(systemId, CLOSEDPLANTINGS), headersWithAuth);

        const apiError = ([active, open, closed] as any as AxiosResponse[]).find((response) => response!.status >= 400);
        if (apiError) {
          console.log(`[Plantings] ${API_FAIL_ERROR}${apiError.status}`);
        }

        if (active?.data) {
          activePlantings.push(convertToDisplayable(systemId, systemName, active.data, PlantingType.Active));
          setActivePlantings([...activePlantings]);
        }
        if (open?.data) {
          openPlantings.push(
            ...open.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Open))
          );
          setOpenPlantings([...openPlantings]);
        }
        if (closed?.data) {
          closedPlantings.push(
            ...closed.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Closed))
          );
          setClosedPlantings([...closedPlantings]);
        }

        // no server access, so mark the server as being offline
      } catch (error) {
        console.log(`[Plantings] ${API_FAIL_ERROR}${error as AxiosError}`);
      }
    };

    systems.forEach((system) => getAllPlantings(system.systemId, system.systemName));
    setPlantingsRequested(true);
  }, [
    activePlantings,
    closedPlantings,
    getFarmName,
    getFieldName,
    getSeedlotName,
    getVarietyName,
    headersWithAuth,
    openPlantings,
    plantingsRequested,
    systems,
  ]);

  async function doLogin() {
    navigate("/login");
  }

  async function doLogout() {
    await Auth.signOut();
    resetApp();
    navigate("/login");
  }

  const queryClient = new QueryClient();

  const frontEndContext: FrontEndContextInterface = {
    handleAuthentication,
    headersWithAuth,
    isAuthenticating,
    isAuthenticated,
    doLogin,
    doLogout,
    systems,
    systemsLoaded: systems.length > 0 && systems.every((system) => system.loaded),
    activePlantings,
    openPlantings,
    closedPlantings,
    getFarmName,
    getFieldName,
    getSeedlotName,
    getVarietyName,
  };

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FrontEndContext.Provider value={frontEndContext}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/planting/:systemId/:id" element={<Planting />} />
            <Route path="/live/:systemId/:id" element={<Live />} />
            <Route path="/lost" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </FrontEndContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
