import { faBatteryFull, faTimes, faWifi } from "@fortawesome/free-solid-svg-icons";
import "@fontsource/roboto";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ChartColumnTopComponentProps {
  PSI: string;
  batteryLevel: string;
  enabled: boolean;
}

export function ChartColumnTopComponent(props: ChartColumnTopComponentProps) {
  const { PSI, batteryLevel, enabled } = props;
  const buttonType = enabled ? "btn-success success-color" : "btn-secondary";
  const buttonIcon = enabled ? faWifi : faTimes;
  const psiNumber = Number.parseFloat(PSI);
  const psiDisplayString = psiNumber.toFixed(1);
  const psiColor = (psiNumber < 1.5 || psiNumber > 2.5) && !isNaN(psiNumber) && enabled ? "red" : "black";
  return (
    <div className="h-100 border">
      <div className="h-50 ">
        <div className="h-100 d-flex row px-2 pt-1">
          <div
            className="h-100 col-8 d-flex px-1 justify-content-center align-items-center fw-bold"
            style={{ color: psiColor }}
          >
            {enabled ? psiDisplayString : "-"} PSI
          </div>
          <div
            className="h-100 col-4 p-0 text-center small-font chart-top-battery-line-height"
            style={{ visibility: enabled ? "visible" : "hidden" }}
          >
            <div className="h-50 d-flex justify-content-center align-items-end">
              <FontAwesomeIcon icon={faBatteryFull as any} className="xxs" />
            </div>
            <div className="h-50 small-font">{batteryLevel}%</div>
          </div>
        </div>
      </div>
      <div className="h-50 d-flex justify-content-center align-items-center py-1">
        <div className="d-flex justify-content-center">
          <button type="button" className={`btn ${buttonType} py-0`}>
            <FontAwesomeIcon icon={buttonIcon as any} />
          </button>
        </div>
      </div>
    </div>
  );
}
