import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable, { FilterType, SelectableRows } from "mui-datatables";
import { Button } from "@mui/material";
import { formatTime } from "../../utils";
import { DisplayablePlantingData } from "../../config";
import "./index.css";

interface FieldLogListProps {
  dataLoaded: boolean;
  activePlantings?: DisplayablePlantingData[];
  openPlantings?: DisplayablePlantingData[];
  closedPlantings?: DisplayablePlantingData[];
}

export default function FieldLogList(props: FieldLogListProps) {
  const { dataLoaded, activePlantings, openPlantings, closedPlantings } = props;
  const navigate = useNavigate();
  const [plantings, setPlantings] = useState<DisplayablePlantingData[]>([]);

  const infoOptions = {
    setCellProps: () => ({
      align: "center",
    }),
  };

  const viewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
      <Button variant="contained" onClick={() => navigate(`/planting/${value}`)}>
        View
      </Button>
    ),
  };

  const dateOptions = {
    customBodyRender: (value: any) => formatTime(value),
    setCellProps: () => ({
      align: "center",
    }),
  };

  const COLUMNS = [
    { label: " ", name: "id", options: viewOptions },
    { label: "System", name: "systemName", options: infoOptions },
    { label: "Farm", name: "farm", options: infoOptions },
    { label: "Field", name: "field", options: infoOptions },
    { label: "Variety", name: "variety", options: infoOptions },
    { label: "Seedlot", name: "seedlot", options: infoOptions },
    { label: "Range", name: "range", options: infoOptions },
    { label: "Acreage", name: "acreage", options: infoOptions },
    { label: "Seed Spacing", name: "seedSpacing", options: infoOptions },
    { label: "Seed Weight", name: "seedWeight", options: infoOptions },
    { label: "Last Updated", name: "updateTime", options: dateOptions },
  ];

  const OPTIONS = {
    download: false,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "updateTime",
      direction: "desc" as any,
    },
    storageKey: "all-plantings",
    textLabels: {
      body: {
        noMatch: dataLoaded ? "No field logs available." : "Loading ...",
      },
    },
  };

  useEffect(() => {
    if (!dataLoaded) {
      return;
    }

    const plantings: DisplayablePlantingData[] = [];

    if (activePlantings) {
      for (const planting of activePlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.id === planting.id)) {
          plantings.push(planting);
        }
      }
    }

    if (openPlantings) {
      for (const planting of openPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.id === planting.id)) {
          plantings.push(planting);
        }
      }
    }

    if (closedPlantings) {
      for (const planting of closedPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.id === planting.id)) {
          plantings.push(planting);
        }
      }
    }

    setPlantings(plantings);
  }, [dataLoaded, activePlantings, closedPlantings, openPlantings]);

  return (
    <div className="field-log-table">
      <MUIDataTable title={"Field Logs"} data={plantings} columns={COLUMNS} options={OPTIONS} />
    </div>
  );
}
