import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import LiveViewList from "../../components/LiveViewList";
import FieldLogList from "../../components/FieldLogList";
import "./index.css";

const HEADER_NAME = "";

const Home = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isAuthenticating, systemsLoaded, activePlantings, openPlantings, closedPlantings } =
    React.useContext(FrontEndContext);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
      <div className="home-container">
        <div className="live-view-container py-3">
          <LiveViewList dataLoaded={systemsLoaded} activePlantings={activePlantings} />
        </div>
        <div className="field-log-container">
          <FieldLogList
            dataLoaded={systemsLoaded}
            activePlantings={activePlantings}
            openPlantings={openPlantings}
            closedPlantings={closedPlantings}
          />
        </div>
      </div>
    </Page>
  );
};

export default Home;
