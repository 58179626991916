import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { Col, ListGroup, Row } from "react-bootstrap";
import FrontEndContext from "../../context/FrontEndContext";
import NotFound from "../NotFound";
import Page from "../../components/Page";
import PlantingSummaryGraph from "../../components/PlantingSummaryGraph";
import PlantingSummaryTable, { PlantingSummaryData } from "../../components/PlantingSummaryTable";
import { formatTime } from "../../utils";
import "./index.css";
import config from "../../config";

const { getUrl, PLANTING, PLANTINGCOUNTS } = config?.api;

interface PlantingCounts {
  ideal: number;
  notIdeal: number;
  doubles: number;
  misses: number;
}

interface WheelPlantingCounts extends PlantingCounts {
  wheel: number;
}

interface PlantingCountsData {
  totals: PlantingCounts;
  wheels: WheelPlantingCounts[];
}

interface PlantingData {
  id: number;
  fieldId: number;
  farmId: number;
  varietyId: number;
  seedlotId: number;
  range: number;
  acreage: number;
  seedSpacingId: number;
  seedWeight: number;
  updateTime: string;
}

const formatPlantingSummaryData = (plantingCounts: PlantingCountsData): PlantingSummaryData[] => {
  const plantingSummaryData: PlantingSummaryData[] = [];
  const { wheels } = plantingCounts;
  for (let i = 0; i < 6; i++) {
    const wheelCounts: WheelPlantingCounts = wheels[i];
    const { ideal, notIdeal, doubles, misses, wheel } = wheelCounts;
    const successPercentage = (ideal * 100.0) / (ideal + notIdeal + misses + doubles);
    const transformedPoint: PlantingSummaryData = {
      "Wheel Number": wheel || 0,
      Misses: misses,
      Doubles: doubles,
      "Not Ideal": notIdeal,
      Ideal: ideal,
      "Ideal%": successPercentage,
      "% Ideal": successPercentage,
      name: `Row ${wheel || 0}`,
    };
    plantingSummaryData.push(transformedPoint);
  }
  return plantingSummaryData.sort((a, b) => a["Wheel Number"] - b["Wheel Number"]);
};

const HEADER_NAME = "";

const Planting = () => {
  const { systemId, id: paramId } = useParams();
  const id = parseInt(paramId!, 10);
  const { headersWithAuth, isAuthenticating, isAuthenticated, getFarmName, getFieldName, getVarietyName } =
    React.useContext(FrontEndContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  const { data: summaryInfo } = useQuery(`FieldSummary-${id}-${systemId}`, async () => {
    if (!systemId) {
      return null;
    }

    const { data: plantingCounts } = await axios.get<PlantingCountsData>(
      getUrl(systemId, PLANTINGCOUNTS, id),
      headersWithAuth
    );
    const { data: plantingInfo } = await axios.get<PlantingData>(getUrl(systemId, PLANTING, id), headersWithAuth);

    return { plantingCounts, plantingInfo };
  });

  const { plantingCounts, plantingInfo } = summaryInfo || {};
  if (isAuthenticating || !plantingCounts || !plantingInfo) {
    return <div>Loading...</div>;
  }

  if (isNaN(id)) {
    return <NotFound />;
  }

  const { fieldId, farmId, updateTime = new Date().toString(), varietyId } = plantingInfo || {};
  const formattedUpdateTime = formatTime(updateTime);
  const formattedGraphData = formatPlantingSummaryData(plantingCounts!);

  const farmName = getFarmName(systemId!, farmId);
  const fieldName = getFieldName(systemId!, fieldId);
  const varietyName = getVarietyName(systemId!, varietyId);

  return (
    <Page name={HEADER_NAME}>
      <div className="planting-container pt-2">
        {" "}
        <div className="notes">
          <h4 className="pb-1">
            <Row className="py-0">
              <Col className={"text-column"} xs={6}>
                Farm: {farmName || "-"}
              </Col>
              <Col className={"text-column"} xs={6}>
                Field Name: {fieldName || "-"}
              </Col>
            </Row>
            <Row>
              <Col className={"text-column"} xs={6}>
                Variety: {varietyName || "-"}
              </Col>
              <Col className={"text-column"} xs={6}>
                Planted Date: {formattedUpdateTime}
              </Col>
            </Row>
          </h4>
          <ListGroup>
            <PlantingSummaryGraph data={formattedGraphData} />
            <div style={{ width: "100%", height: 10 }}> </div>
            <PlantingSummaryTable wheelsInfo={formattedGraphData} />
          </ListGroup>
        </div>
      </div>
    </Page>
  );
};

export default Planting;
