import React from "react";
import { ApiFarmData, ApiFieldData, ApiSeedlotData, ApiVarietyData, DisplayablePlantingData } from "../config";

export interface SystemInfo {
  loaded: boolean;
  systemId: string;
  systemName: string;
  farms: ApiFarmData[];
  fields: ApiFieldData[];
  seedlots: ApiSeedlotData[];
  varieties: ApiVarietyData[];
}

export interface FrontEndContextInterface {
  handleAuthentication: (userSession: any, error?: any) => void;
  headersWithAuth: any;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  doLogin: () => void;
  doLogout: () => void;
  systems: SystemInfo[];
  systemsLoaded: boolean;
  activePlantings: DisplayablePlantingData[];
  openPlantings: DisplayablePlantingData[];
  closedPlantings: DisplayablePlantingData[];
  getFarmName: (systemId: string, farmId: number) => string;
  getFieldName: (systemId: string, fieldId: number) => string;
  getSeedlotName: (systemId: string, seedlotId: number) => string;
  getVarietyName: (systemId: string, varietyId: number) => string;
}

const FrontEndContext = React.createContext<FrontEndContextInterface>({
  handleAuthentication: (userSession: any, error?: any) => {},
  headersWithAuth: {},
  isAuthenticating: false,
  isAuthenticated: false,
  doLogin: () => {},
  doLogout: () => {},
  systems: [],
  systemsLoaded: false,
  activePlantings: [],
  openPlantings: [],
  closedPlantings: [],
  getFarmName: () => "",
  getFieldName: () => "",
  getSeedlotName: () => "",
  getVarietyName: () => "",
});

export default FrontEndContext;
