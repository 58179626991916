const SERVER_SCHEME = "https";
const SERVER_HOST = process.env.REACT_APP_API;
const SERVER_URL = `${SERVER_SCHEME}://${SERVER_HOST}`;
const API_URL = `${SERVER_URL}/system`;

const FARMS = `Farms`;
const FIELDS = `Fields`;
const SEEDLOTS = `Seedlots`;
const VARIETIES = `Varieties`;
const PLANTING = `Planting`;
const OPENPLANTINGS = `${PLANTING}/OpenPlantings`;
const CLOSEDPLANTINGS = `${PLANTING}/ClosedPlantings`;
const ACTIVEPLANTING = `${PLANTING}/ActivePlanting`;
const PLANTINGCOUNTS = `${PLANTING}/Counts`;

const SYSTEM = `System`;
const STATUS = `${SYSTEM}/Status`;
const SENSORS = `Sensors`;
const LIVECOUNTS = `${SENSORS}/LiveCounts`;

const API_FAIL_ERROR = "Could not fetch data from server";

// IMPORTANT: do NOT deploy to production with this value set to true
const LOCAL_TESTING = false;

const NEEDS_ID = [PLANTING, PLANTINGCOUNTS];
const SYSTEMS = LOCAL_TESTING ? `http://localhost:5000/systems` : `${SERVER_URL}/systems`;
const getUrl = (systemId: string, endpoint: string, id?: number) => {
  const server = LOCAL_TESTING ? `http://localhost:5000/api` : `${API_URL}/${systemId}`;
  return `${server}/${endpoint}${id !== undefined && NEEDS_ID.includes(endpoint) ? `/${id}` : ""}`;
};

const config = {
  s3: {
    REGION: "ca-central-1",
    BUCKET: "config-bucket-325591102945-ca-central-1",
  },
  apiGateway: {
    REGION: "ca-central-1",
    URL: process.env.REACT_APP_API,
    // URL: "https://h91k6qrc66.execute-api.ca-central-1.amazonaws.com",
  },
  cognito: {
    REGION: "ca-central-1",
    // USER_POOL_ID: "ca-central-1_31ljY5HP3",
    // APP_CLIENT_ID: "25ohksmeo9inifjm6ro59dnsti",
    USER_POOL_ID: "ca-central-1_jcjPPkAou",
    APP_CLIENT_ID: "34boo7cadv22ghh7amldvgp8o7",
    IDENTITY_POOL_ID: "None",
  },
  api: {
    SYSTEMS,
    FARMS,
    FIELDS,
    SEEDLOTS,
    VARIETIES,
    PLANTING,
    OPENPLANTINGS,
    CLOSEDPLANTINGS,
    ACTIVEPLANTING,
    PLANTINGCOUNTS,
    API_FAIL_ERROR,
    STATUS,
    LIVECOUNTS,
    getUrl,
  },
};

export enum PlantingType {
  Active,
  Open,
  Closed,
}

export const PLANTING_TYPE_NAMES = {
  [PlantingType.Active]: "Active",
  [PlantingType.Open]: "Open",
  [PlantingType.Closed]: "Closed",
};

export interface ApiPlantingData {
  id: number;
  farmId: number;
  fieldId: number;
  varietyId: number;
  seedlotId: number;
  range: number;
  acreage: number;
  seedSpacingId: number;
  seedWeight: number;
  updateTime: string;
}

export interface PlantingData extends ApiPlantingData {
  type: PlantingType;
}

export interface DisplayablePlantingData {
  systemName: string;
  id: string;
  type: string;
  farm: string;
  field: string;
  variety: string;
  seedlot: string;
  range: number;
  acreage: number;
  seedSpacing: number;
  seedWeight: number;
  updateTime: string;
}

export interface ApiFarmData {
  id: number;
  name: string;
}

export interface ApiFieldData {
  id: number;
  name: string;
  farmId: number;
}

export interface ApiSeedlotData {
  id: number;
  name: string;
}

export interface ApiVarietyData {
  id: number;
  name: string;
}

export interface ApiSeedSpacingData {
  id: number;
  space: number;
}

export interface SystemStatusData {
  seeding: boolean;
  lowSpeed: boolean;
  currentSpeedMph: number;
  averageSpacingInches: number;
  acreage: number;
  targetSpacing: number;
  cWeightPerAcre: number;
  totalCWeight: number;
  tpvMode: number;
}

export interface WheelLiveCountsData {
  status: number;
  wheelNumber: number;
  wheelSpeed: number;
  ideal: number;
  notIdeal: number;
  doubles: number;
  misses: number;
  distance: number;
  outOfRange: boolean;
  wheelPressure: number;
  staticPressureAlarm: boolean;
  lowWheelSpeed: boolean;
  batteryStateOfCharge: number;
  batteryStateOfChargeAlarm: boolean;
  successPercent: number;
  successPercentRow: number;
}

export default config;
