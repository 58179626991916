import React from "react";
import Button from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";
import "./index.css";

interface LoginButtonProps {
  isLoading: boolean;
  disable: boolean;
  className?: string;
  [x: string]: any;
}

export default function LoginButton(props: LoginButtonProps) {
  const { isLoading, disable, ...rest } = props;
  return (
    <Button disabled={disable || isLoading} className="LoginButton" {...rest}>
      {isLoading && <BsArrowRepeat className="spinning" />}
      {props.children}
    </Button>
  );
}
