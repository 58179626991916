import React from "react";
import { Table } from "react-bootstrap";

export interface PlantingSummaryParams {
  plantingId: string;
}

export interface PlantingSummaryData {
  name: string;
  "% Ideal": number;
  "Wheel Number": number;
  Misses: number;
  Doubles: number;
  "Not Ideal": number;
  Ideal: number;
  "Ideal%": number;
}

const roundToTwo = (x: number) => {
  return Math.round((x + Number.EPSILON) * 100) / 100;
};

const formatNumber = (x: number) => {
  return x.toLocaleString();
};

const formatValue = (x: number) => {
  return formatNumber(roundToTwo(x));
};

interface PlantingSummaryTableProps {
  wheelsInfo: PlantingSummaryData[];
}

export default function PlantingSummaryTable(props: PlantingSummaryTableProps) {
  const { wheelsInfo } = props;
  const wheelNumbers = [1, 2, 3, 4, 5, 6];
  const rowTypes = ["Misses", "Doubles", "Not Ideal", "Ideal", { key: "Ideal%", label: "% Ideal" }];
  return (
    <>
      <Table striped bordered hover size="sm" className="mb-0">
        <thead>
          <tr>
            <th>Rows #</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {rowTypes.map((rowType) => {
            let ncRowLabel = null;
            let ncRowKey = null;
            if (typeof rowType !== "string") {
              ncRowLabel = rowType.label;
              ncRowKey = rowType.key;
            } else {
              ncRowLabel = rowType;
              ncRowKey = rowType;
            }
            const rowLabel: string = ncRowLabel;
            const rowKey: string = ncRowKey;
            let total = 0;
            return (
              <tr key={`row-${rowKey}`}>
                <td key={`row-${rowKey}-label`}>{rowLabel}</td>
                {wheelNumbers.map((wheelNumber) => {
                  const wheelIndex = wheelNumber - 1;
                  const wheelInfo = wheelsInfo[wheelIndex];
                  if (wheelInfo == null) return null;
                  //@ts-ignore
                  const rowValue = wheelInfo[rowKey] || 0;
                  total += rowValue;
                  return <td key={`row-${rowKey}-${wheelNumber}`}>{formatValue(rowValue) || "-"}</td>;
                })}
                {rowKey === "Ideal%"
                  ? (() => {
                      total /= 6;
                      return null;
                    })()
                  : null}
                <td key={`row-${rowKey}-total`}>{formatValue(total) || "-"}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
